* {
  font-family: "Hind Madurai", sans-serif;
}

.answer {
  min-height: 380px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  margin: 5px 10px;
  min-height: 275px;
  min-width: 265px;
}

.toggleButton {
  height: 100%;
  border-radius: 5%;
  border: none !important;
  color: #ffedff;
}

.optionName {
  font-family: "Hind Madurai";
  text-transform: uppercase;
  font-size: 1.5em;
  padding-top: 5px;
  background-color: #fce4ec;
}

.imgDiv {
  position: relative;
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.img {
  border-radius: 8px;
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.choiceGrid {
  overflow-y: hidden;
  overflow-x: hidden;
}

.question {
  text-align: right;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}

.question h1 {
  width: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;

  text-align: right;
  letter-spacing: 0.1px;

  color: #373f41;
}

.question button {
  margin: 10px;
  margin-top: 5px;
  margin-right: 30px;
}
