@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai&display=swap');

.App {
  text-align: center;
  font-family: Hind Madurai;
  max-width: 1024px;
  max-height: 700px;
  margin: 30px;
  background-color: #f5f5f6;
}

.ContentContainer {
  padding: 1%;
  height: 700px;
}

.verticalLine {
  width: 3px;
  height: 97%;
  background-color: #c38a9e;
  margin: 3px 10px;
  margin: 3px;
  display: block;
}
