@import url("https://fonts.googleapis.com/css2?family=Hind+Madurai&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-height: 700px;
}

nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: "Hind Madurai", sans-serif;
  font-weight: 700;
  overflow-x: hidden;
  /* When content overflows horizontally, they will be hidden */
  max-height: 700px;
}

.navLinks,
.count {
  display: flex;
  justify-content: space-around;
  width: 15%;
  max-width: 350px;

  position: absolute;
  /* height of navbar */
  height: 690px;
  top: 33px;
  opacity: 1;

  flex-direction: column;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in;
  /* Transition of the navbar */
}

.navLinks {
  align-items: flex-end;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* The container of the will have a shadow */
}

.count {
  transform: translateX(-155%);
  top: 0;
}

.navLinks li,
.navLinks NavItems {
  display: inline-flex;
  list-style: none;
  text-decoration: none;
  color: rgb(70, 69, 69);
  letter-spacing: 3px;
  font-weight: 400;
  font-size: 20px;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.navLinks li span,
.navLinks NavItems span {
  justify-self: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}

.count li,
.count NavCountItems,
.count div {
  position: relative;
  display: inline-block;

  list-style: none;
  text-decoration: none;

  align-self: flex-end;
  text-align: center;

  width: 50px;
  height: 50px;
  line-height: 47.5px;
  color: rgb(70, 69, 69);
  font-size: 20px;

  font-weight: 400;

  background-color: #f7bacf;

  border-radius: 25px;

  opacity: 0;
}

.navLinks a {
  text-decoration: none;
  color: rgb(70, 69, 69);
  letter-spacing: 3px;
  font-weight: 400;
  font-size: 14px;
}

div.navCountStatic div.navCountAni {
  opacity: 1;
  padding-left: 25px;
  text-transform: capitalize;
  width: 250px;
  background: #f7bacf;
  position: absolute;
  left: 0;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

div.navCountStatic {
  opacity: 1;
  background: #f7bacf;
  position: absolute;
  z-index: -1;
  cursor: pointer;
  top: 0;
  left: 0;
}

.burger {
  cursor: pointer;
  padding: 10px;
  position: relative;
  z-index: 3;
  background-color: #ffeeff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.burger.shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: rgb(70, 69, 69);
  margin: 5px;
  transition: all 0.3s ease;
  display: block;
}

nav .navActive {
  transform: translateX(0%);
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}